import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/index",
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      name: "登录"
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('token');
      if (token) {
        next('/index');
      } else {
        next();
      }
    }
  },
  {
    path: '/index',
    component: () => import('../views/index/index.vue'),
    meta: {
      name: "招聘首页"
    },
  },
  {
    path: '/practice',
    component: () => import('../views/practice/practice.vue'),
    meta: {
      name: "实习专区"
    },
  },
  {
    path: '/schoolRecruitment',
    component: () => import('../views/schoolRecruitment/schoolRecruitment.vue'),
    meta: {
      name: "校园招聘"
    }
  },
  {
    path: '/taction',
    component: () => import('../views/taction/taction.vue'),
    meta: {
      name: "招聘行动"
    }
  },
  {
    path: '/pInformation',
    component: () => import('../views/pInformation/pInformation.vue'),
    meta: {
      name: "个人信息"
    }
  },
  {
    path: '/cInformation',
    component: () => import('../views/cInformation/cInformation.vue'),
    meta: {
      name: "法人信息"
    }
  },
  {
    path: '/eInformation',
    component: () => import('../views/eInformation/eInformation.vue'),
    meta: {
      name: "企业信息"
    }
  },
  {
    path: '/company',
    component: () => import('../views/company/company.vue'),
    meta: {
      name: "企业详情"
    }
  },
  {
    path: '/companyHomepage',
    component: () => import('../views/companyHomepage/companyHomepage.vue'),
    meta: {
      name: "公司主页"
    }
  },
  {
    path: '/position',
    component: () => import('../views/position/position.vue'),
    meta: {
      name: "职位详情"
    }
  },
  // {
  //   path: '/headhuntersChat',
  //   component: () => import('../views/headhuntersChat/headhuntersChat.vue'),
  //   meta: {
  //     name: "猎头与企业聊天"
  //   }
  // },
  {
    path: '/jobList',
    component: () => import('../views/jobList/jobList.vue'),
    meta: {
      name: "职位列表"
    },
    // beforeEnter(to, from, next) {
    //   if (localStorage.getItem("token")) {
    //     next()
    //   }else{
    //     Message("请先登录")
    //     next('/login')
    //   }
    // }
  },
  {
    path: '/personalCenter',
    component: () => import('../views/personalCenter/personalCenter.vue'),
    meta: {
      name: "个人中心"
    }
  },
  {
    path: '/deliveryRecords',
    component: () => import('../views/deliveryRecords/deliveryRecords.vue'),
    meta: {
      name: "投递记录"
    }
  },
  {
    path: '/myResumeAfter',
    component: () => import('../views/myResumeAfter/myResumeAfter.vue'),
    meta: {
      name: "我的简历编辑后"
    }
  },
  {
    path: '/myResume',
    component: () => import('../views/myResume/myResume.vue'),
    meta: {
      name: "我的简历"
    }
  },
  {
    path: '/enterpriseIndex',
    component: () => import('../views/enterpriseIndex/enterpriseIndex.vue'),
    meta: {
      name: "企业端首页"
    }
  },
    {
        path: '/companyMeeting',
        component: () => import('../views/company-meeting/index.vue'),
        meta: {
            name: "我报名的会议"
        }
    },
    {
        path: '/meetingDetails',
        component: () => import('../views/meeting-details/index.vue'),
        meta: {
            name: "会议详情"
        }
    },
  {
    path: '/recruitHomepage',
    component: () => import('../views/recruitHomepage/recruitHomepage.vue'),
    meta: {
      name: "招聘主页"
    }
  },
  {
    path: '/createInterview',
    component: () => import('../views/createInterview/createInterview.vue'),
    meta: {
      name: "创建面试"
    }
  },
  {
    path: '/onlineInterview',
    component: () => import('../views/onlineInterview/onlineInterview.vue'),
    meta: {
      name: "在线面试列表"
    }
  },
  {
    path: '/onlineBacktone',
    component: () => import('../views/onlineBacktone/onlineBacktone.vue'),
    meta: {
      name: "在线背调列表"
    }
  },
  {
    path: '/createBacktone',
    component: () => import('../views/createBacktone/createBacktone.vue'),
    meta: {
      name: "创建背调"
    }
  },
  {
    path: '/editInfo',
    component: () => import('../views/editInfo/editInfo.vue'),
    meta: {
      name: "编辑企业基本信息"
    }
  },
  {
    path: '/postPosition',
    component: () => import('../views/postPosition/postPosition.vue'),
    meta: {
      name: "企业发布职位"
    }
  },
    {
        path: '/postPositionEdit',
        component: () => import('../views/postPositionEdit/postPosition.vue'),
        meta: {
            name: "企业编辑职位"
        }
    },
  {
    path: '/enterprisePosition',
    component: () => import('../views/enterprisePosition/enterprisePosition.vue'),
    meta: {
      name: "企业职位"
    }
  },
  {
    path: '/talent',
    component: () => import('../views/talent/talent.vue'),
    meta: {
      name: "人才管理"
    }
  },
  {
    path: '/recruitmentData',
    component: () => import('../views/recruitmentData/recruitmentData.vue'),
    meta: {
      name: "招聘数据"
    }
  },
  {
    path: '/DoubleChoiceMeeting',
    component: () => import('../views/DoubleChoiceMeeting/DoubleChoiceMeeting.vue'),
    meta: {
      name: "双选会"
    }
  },
  {
    path: '/headlineList',
    component: () => import('../views/headlineList/headlineList.vue'),
    meta: {
      name: "头条列表更多"
    }
  },
  {
    path: '/schoolheadlineList',
    component: () => import('../views/schoolheadlineList/schoolheadlineList.vue'),
    meta: {
      name: "校园头条更多"
    }
  },
  {
    path: '/news',
    component: () => import('../views/news/news.vue'),
    meta: {
      name: "新闻"
    }
  },
  {
    path: '/notice',
    component: () => import('../views/notice/notice.vue'),
    meta: {
      name: "资讯"
    }
  },
  {
    path: '/bulletin',
    component: () => import('../views/bulletin/bulletin.vue'),
    meta: {
      name: "公告"
    }
  },
  {
    path: '/newsdetail',
    component: () => import('../views/newsdetail/newsdetail.vue'),
    meta: {
      name: "新闻详情"
    }
  },
  {
    path: '/noticedetail',
    component: () => import('../views/noticedetail/noticedetail.vue'),
    meta: {
      name: "资讯详情"
    }
  },
  {
    path: '/jobfair',
    component: () => import('../views/jobfair/jobfair.vue'),
    meta: {
      name: "招聘会"
    }
  },
  {
    path: '/jobfairInfo',
    component: () => import('../views/jobfairInfo/jobfairInfo.vue'),
    meta: {
      name: "招聘会详情"
    }
  },
  {
    path: '/jobSearchGuidance',
    component: () => import('../views/jobSearchGuidance/jobSearchGuidance.vue'),
    meta: {
      name: "求职指导"
    }
  },
  {
    path: '/SearchGuidanceInfo',
    component: () => import('../views/SearchGuidanceInfo/SearchGuidanceInfo.vue'),
    meta: {
      name: "求职指导详情"
    }
  },
  {
    path: '/book',
    component: () => import('../views/book/book.vue'),
    meta: {
      name: "职场宝典"
    }
  },
  {
    path: '/bookinfo',
    component: () => import('../views/bookinfo/bookinfo.vue'),
    meta: {
      name: "职场宝典详情"
    }
  },
  {
    path: '/ListBusinesses',
    component: () => import('../views/ListBusinesses/ListBusinesses.vue'),
    meta: {
      name: "企业列表"
    }
  },
  {
    path: '/DoubleElection',
    component: () => import('../views/DoubleElection/DoubleElection.vue'),
    meta: {
      name: "双选会列表"
    }
  },
  {
    path: '/SpecialSessionRecommend',
    component: () => import('../views/SpecialSessionRecommend/SpecialSessionRecommend.vue'),
    meta: {
      name: "专场推荐列表"
    }
  },
  {
    path: '/liveEvent',
    component: () => import('../views/liveEvent/liveEvent.vue'),
    meta: {
      name: "直播活动列表"
    }
  },
  {
    path: '/message',
    component: () => import('../views/message/message.vue'),
    meta: {
      name: "消息"
    }
  },
  {
    path: '/teachin',
    component: () => import('../views/teachin/teachin.vue'),
    meta: {
      name: "宣讲会列表"
    }
  },
  {
    path: '/teachininfo',
    component: () => import('../views/teachininfo/teachininfo.vue'),
    meta: {
      name: "宣讲会详情"
    }
  },
  {
    path: '/myInterview',
    component: () => import('../views/myInterview/myInterview.vue'),
    meta: {
      name: "我的面试"
    }
  },
  {
    path: '/collect',
    component: () => import('../views/collect/collect.vue'),
    meta:{
      name: "关注收藏"
    }
  },
  {
    path: '/corporateHonors',
    component: () => import('../views/corporateHonors/corporateHonors.vue'),
    meta:{
      name: "企业荣誉"
    }
  },
  {
    path: '/bannerinfo',
    component: () => import('../views/bannerinfo/bannerinfo.vue'),
    meta:{
      name: "轮播图详情"
    }
  },
  {
    path: '/editPersonal',
    component: () => import('../views/editPersonal/editPersonal.vue'),
    meta:{
      name: "账号设置"
    }
  },
  {
    path: '/interview',
    component: () => import('../views/interview/interview.vue'),
    meta: {
      name: "个人中心面试"
    }
  },
  {
    path: '/FollowUnits',
    component: () => import('../views/FollowUnits/FollowUnits.vue'),
    meta: {
      name: "个人中心关注单位"
    }
  },
    {
        path: '/postPositionEdit',
        component: () => import('../views/postPositionEdit/postPosition.vue'),
        meta: {
            name: "企业编辑职位"
        }
    },
  {
    path: '/BrowseJobs',
    component: () => import('../views/BrowseJobs/BrowseJobs.vue'),
    meta: {
      name: "个人中心浏览职位"
    }
  },
  {
    path: '/detail',
    component: () => import('../views/detail/detail.vue'),
    meta: {
      name: "校招头条详情"
    }
  },
    {
        path: '/chat',
        component: () => import('../views/company-chat/index.vue'),
        meta: {
            name: "聊天"
        }
    },
    {
        path: '/userChat',
        component: () => import('../views/user-chat/index.vue'),
        meta: {
            name: "聊天"
        }
    },
  {
    path: '/pageNotFound',
    name: 'pageNotFound',
    component: () => import("../views/page-not-found/pagenotfound.vue")
  },
    {
        path: '/biographicalNotes',
        name: 'biographicalNotes',
        component: () => import("../views/biographical-notes/index.vue")
    },
  {
    path: '*',
    redirect: '/pageNotFound',
    name: 'notFound',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

export default router
