
let BASE_URL = ''
const TIME_OUT = 600000
console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') { 
    BASE_URL = 'https://rcdn.1qit.com'
    //BASE_URL = 'https://spring6.cn:9344'
} else if (process.env.NODE_ENV === 'production') {
	BASE_URL = 'https://rcdn.1qit.com'
    //BASE_URL = 'https://spring6.cn:9344'
} else {
    BASE_URL = ''
}

export { BASE_URL, TIME_OUT }
