import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'; // 引入 Vuex store
import Vuex from 'vuex';
Vue.use(Vuex);  


// 引入ElementUI插件
import ElementUI from 'element-ui'
// 引入样式
import 'element-ui/lib/theme-chalk/index.css'
// 使用插件
Vue.use(ElementUI);

import {MessageBox} from 'element-ui'
Vue.prototype.$confirm1=MessageBox.confirm;

// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap , {
    ak:"WEA8fSoAK2rcx18sLgjeNp6lDSlaODyq"
})

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);

import service from './utils/service' //全局工具-网络请求
Vue.prototype.$service = service;

import LocalCache from './utils/catch/cache' //全局工具-缓存增删改查
Vue.prototype.$cache = LocalCache;

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
