import BaseRequest from './request'
import { BASE_URL,TIME_OUT } from '../config'
import LocalCache from '../catch/cache'
import { Message } from 'element-ui';
import router from '../../router/index'

const service = new BaseRequest({
	baseURL: BASE_URL,
	timeout: TIME_OUT,
	interceptors: {
		requestInterceptor: (config) => {
			if (config.url != "/api/zp/v1/zp_login") {
				const token = LocalCache.getCache('token') || ""
				config.headers['token'] = token;
			}
			return config;
		},
		requestInterceptorCatch: (error) => {
			return error;
		},
		responseInterceptor: (res) => {
			if (res.data.code === -1) {
				LocalCache.clearCache()
                Message('登录过期，请重新登录');
				router.push('/')
			}
			return res.data
		},
		responseInterceptorCatch: (error) => {
            console.log(error)
			return error
		}
	}
})
export default service
