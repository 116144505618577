import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'; 
import { BASE_URL } from '../utils/config'

axios.defaults.baseURL = BASE_URL;

Vue.use(Vuex);

export async function fetchIndexBase() {
    const res = await axios.get('/api/zp/v1/zp_index_base');
    return res.data;
}

export default new Vuex.Store({
    state: {
        IndexBase: null,
    },
    mutations: {
        setIndexBase(state, data) {
            state.IndexBase = data;
        },
    },
    actions: {
        async fetchIndexBase({ commit }) {
            try {
                const data = await fetchIndexBase();
                if (data && data.code === 1) { 
                    commit('setIndexBase', data.info);
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
});